import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { DivTooltipContentStyled } from './RegulationPanelRowValueWithReset.styles';
import { TranslationKeys } from 'app/translations';
import ActionButton from 'app/components/ActionButton';
import { Tooltip } from 'app/components/Tooltip';

type RegulationPanelPanelRowValueWithResetProps = {
  value: React.ReactElement;
  onReset: () => void;
};

export const RegulationPanelPanelRowValueWithReset = ({
  value,
  onReset,
}: RegulationPanelPanelRowValueWithResetProps) => {
  const tooltipText = React.useMemo(() => {
    return (
      <DivTooltipContentStyled>
        <Typography variant="body1">
          <FormattedMessage id={TranslationKeys.resettableValue_tooltipText} />
        </Typography>
        <ActionButton variant={'text'} size={'small'} onClick={onReset} messageId={TranslationKeys.global_revert} />
      </DivTooltipContentStyled>
    );
  }, [onReset]);

  return (
    <Tooltip title={tooltipText} placement={'right-end'}>
      {value}
    </Tooltip>
  );
};
