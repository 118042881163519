import { createSelector } from 'reselect';

import { tableInitialState } from '../../App/Ducks/Table.duck';

export const actionTypes = {
  EventsTable: '[PensioenbijEvents] EventsTable',
  ChangePage: '[PensioenbijEvents] change page',
  SetPageSize: '[PensioenbijEvents] set page size',
  SelectAllRows: '[PensioenbijEvents] select all rows',
  ToggleRow: '[PensioenbijEvents] select row',
  ApplyFilter: '[[PensioenbijEvents] EventsTable] Apply filter',
} as const;

export const pensioenbijDuckInitialState = {
  ...tableInitialState,
  selectedRows: [] as string[],
  filters: {
    status: ['failed', 'pending'],
  } as Record<string, any>,
};

export type PensioenbijDuckState = typeof pensioenbijDuckInitialState;

export const reducer = (state = pensioenbijDuckInitialState, action: Record<string, any>): PensioenbijDuckState => {
  switch (action.type) {
    case actionTypes.EventsTable:
      return {
        ...state,
      };

    case actionTypes.ChangePage:
      return {
        ...state,
        page: action.payload.page,
      };

    case actionTypes.SetPageSize:
      return {
        ...state,
        pageSize: action.payload.pageSize,
      };

    case actionTypes.ApplyFilter:
      return {
        ...state,
        page: 1,
        pageSize: state.pageSize,
        filters: {
          [action.payload.name]: action.payload.value,
        },
        selectedRows: [],
      };

    case actionTypes.SelectAllRows:
      return {
        ...state,
        selectedRows: action.payload.ids,
      };

    case actionTypes.ToggleRow:
      return {
        ...state,
        selectedRows: !state.selectedRows.includes(action.payload.row)
          ? [...state.selectedRows, action.payload.row]
          : state.selectedRows.filter(row => row !== action.payload.row),
      };

    default:
      return state;
  }
};

export const actions = {
  applyFilter: (name: any, value: any) => {
    return {
      type: actionTypes.ApplyFilter,
      payload: {
        [name]: value,
      },
    };
  },

  changePage: (page: number) => {
    return {
      type: actionTypes.ChangePage,
      payload: {
        page,
      },
    };
  },

  setPageSize: (pageSize: number) => {
    return {
      type: actionTypes.SetPageSize,
      payload: {
        pageSize,
      },
    };
  },

  selectAllRows: (ids: string[]) => {
    return {
      type: actionTypes.SelectAllRows,
      payload: {
        ids,
      },
    };
  },

  toggleRow: (row: string) => {
    return {
      type: actionTypes.ToggleRow,
      payload: {
        row,
      },
    };
  },
};

export const selectors = {
  selectEventTables: createSelector(
    (state: PensioenbijDuckState) => state,
    eventTables => eventTables,
  ),
};
