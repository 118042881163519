import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { CardContent, Typography } from '@mui/material';

import { TranslationKeys } from '../../../../translations';

import { OWNER } from '../../../../common/Authorization/entities';
import { EDIT } from '../../../../common/Authorization/permissions';

import { HasAccessTo } from '../../../../components';

import { CardActionAreaStyled, CardStyled, DivContainerStyled } from './ToolsManagementPage.styles';

const TOOLS = [
  {
    titleId: TranslationKeys.menu_tools_upaXmlSplitter,
    link: '/tools/splitter',
    isAllowed: () => HasAccessTo(OWNER, EDIT),
  },
  {
    titleId: TranslationKeys.menu_tools_pensioenbij,
    link: '/tools/pensioenbij',
    isAllowed: () => HasAccessTo(OWNER, EDIT),
  },
];

export const ToolsManagementPage = () => {
  const navigate = useNavigate();

  const onToolClick = React.useCallback(
    (link: string) => () => {
      navigate(link);
    },
    [navigate],
  );

  return (
    <DivContainerStyled>
      {TOOLS.map((tool, idx) => {
        if (!tool.isAllowed()) {
          return null;
        }

        return (
          <CardStyled key={`${idx}_${tool.titleId}`} elevation={1}>
            <CardActionAreaStyled onClick={onToolClick(tool.link)}>
              <CardContent>
                <Typography variant="h5">{<FormattedMessage id={tool.titleId} />}</Typography>
              </CardContent>
            </CardActionAreaStyled>
          </CardStyled>
        );
      })}
    </DivContainerStyled>
  );
};
