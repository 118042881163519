import { TranslationKeys } from '../../../../translations';

export const OwnerFormFields = {
  OwnerName: {
    Label: TranslationKeys.owners_name,
    InputName: 'ownerName',
  },
  OwnerEmail: {
    Label: TranslationKeys.owners_ownerEmail,
    InputName: 'ownerEmail',
  },
  OwnerPhone: {
    Label: TranslationKeys.owners_ownerPhone,
    InputName: 'ownerPhone',
  },
  Address: {
    Label: TranslationKeys.owners_address,
    InputName: 'address',
  },
  City: {
    Label: TranslationKeys.owners_city,
    InputName: 'city',
  },
  ZipCode: {
    Label: TranslationKeys.owners_zipCode,
    InputName: 'zipCode',
  },
  ContactName: {
    Label: TranslationKeys.owners_contactName,
    InputName: 'contactName',
  },
  ContactEmail: {
    Label: TranslationKeys.owners_contactEmail,
    InputName: 'contactEmail',
  },
  ContactPhone: {
    Label: TranslationKeys.owners_contactPhone,
    InputName: 'contactPhone',
  },
  Instance: {
    Label: TranslationKeys.global_pensioenbijIntegratie,
    InputName: 'instance',
  },
  KvkNumber: {
    Label: TranslationKeys.global_kvkNumber,
    InputName: 'kvkNumber',
  },
  Logo: {
    Label: TranslationKeys.global_logo,
    InputName: 'logo',
  },
} as const;

export type OwnerFormValues = {
  ownerId?: string | null;
  [OwnerFormFields.OwnerName.InputName]: string;
  [OwnerFormFields.OwnerEmail.InputName]: string;
  [OwnerFormFields.OwnerPhone.InputName]: string;
  [OwnerFormFields.ContactName.InputName]: string;
  [OwnerFormFields.ContactEmail.InputName]: string;
  [OwnerFormFields.ContactPhone.InputName]: string;
  [OwnerFormFields.Address.InputName]: string;
  [OwnerFormFields.ZipCode.InputName]: string;
  [OwnerFormFields.City.InputName]: string;
  [OwnerFormFields.Instance.InputName]: string;
  [OwnerFormFields.KvkNumber.InputName]: string;
  [OwnerFormFields.Logo.InputName]?: string | File;
};
