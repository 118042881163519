import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryWrapper } from 'app/types';
import { useSnakeBar } from '../../useSnakeBar';
import { toolsServices } from '../../../Domain/Tools/Services';

export interface PensioenbijMutation {
  '@id': string;
  '@type': string;
  externalId: string;
  mutationType: string;
  employerKVK: string;
  employerId: string;
  employerName: string;
  employeeID: string;
  employeeRefID: string;
  employerPolicyPlanID: string;
  employeeDateOfBirth: string; // Could be Date if you want to parse it
  employeeDateOfEmployment: string; // Could be Date if you want to parse it
  employeeDateOfEmploymentEnd: string | null;
  employeeFirstName: string;
  employeeLastName: string;
  employeeEmail: string;
  employeeLanguage: string;
  instanceID: string;
  syncStatus: string;
  createdAt: string; // Could be Date if needed
}

type GetPensieonBijEventsQueryVariables = {
  page?: number;
  pageSize?: number;
  status?: string[];
};

type GetPensieonBijEventsQueryResultData = {
  data: PensioenbijMutation[];
  totalItems: number;
};

const queryFn =
  ({ page, pageSize, status }: GetPensieonBijEventsQueryVariables) =>
  async () => {
    const response = await toolsServices.getPensieonBijEvents({
      page,
      pageSize,
      status,
    });
    return response.data;
  };

const QUERY_KEY = 'getPensieonBijEvents';

export const usePensieonBijUnSyncEventsGetAllQuery: UseQueryWrapper<
  GetPensieonBijEventsQueryResultData,
  GetPensieonBijEventsQueryVariables
> = ({ variables }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalItems: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<any>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
  });
};
