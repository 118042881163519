import type { Connection } from './connection.types';

export type EmployerConnection = Pick<
  Connection,
  '@id' | '@type' | 'connectionId' | 'connectionName' | 'connectionType' | 'product'
>;

export type EmployerNote = {
  noteId: string;
  text: string;
  employerId: Employer['employerId'];
  createdAt: string;
  updatedAt: string;
};

export type Employer = {
  '@id': string;
  '@type': string;
  address: string;
  chamberOfCommerce: string;
  city: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  employerEmail: string;
  employerId: string;
  employerName: string;
  employerPhone: string;
  internalNumber?: string;
  owner: string;
  ownerId: string;
  parentId: string | null;
  payrollTaxNumber: string;
  sectorCode: string;
  status: EmployerStatus;
  zipCode: string;
  connections: Array<EmployerConnection>;
  subEmployers: Array<Employer>;
  pensioenbijIntegration?: boolean;
  tMoments: [];
};

export enum EmployerStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  UNDER_CONSTRUCTION = 'underConstruction',
}

export type EmployerFile = {
  fileId: string;
  employerId: Employer['employerId'];
  employeeId: string;
  title: string;
  encodedFilename: string;
  createdAt: string;
  updatedAt: string;
};
