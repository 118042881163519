import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/material';

import {
  ActionLink,
  FilterableTable,
  type FilterableTableColumnType,
  type FilterableTableRowMapperFunc,
} from '../../../../components';
import { Checkbox } from 'app/components';
import { TranslationKeys } from '../../../../translations';
import formatter from '../../../../utils/formatter';
import type { PensioenbijMutation } from '../../../../hooks/api/tools';
import { usePensieonBijUnSyncEventsGetAllQuery } from '../../../../hooks/api/tools';
import { StoreActions, useStoreDispatch } from '../../../../store';
import EventsToolbar from '../../components/PensioenbijEventTableActions/EventsToolbar';
import { useSelector } from 'react-redux';
import { useResendPensioenbijMutation } from '../../../../hooks/api/tools/useResendPensioenbijMutation';
import { useSnakeBar } from '../../../../hooks';

enum TableColumns {
  Checkbox = 'checkbox',
  SyncStatus = 'syncStatus',
  Employee = 'employeeName',
  Employer = 'employerName',
  StartDate = 'startDate',
  MutationType = 'mutationType',
}

export const PensioenbijPage = () => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const dispatch = useStoreDispatch();
  const { mutate: resendSelectedMutations } = useResendPensioenbijMutation();

  const { page, pageSize, selectedRows, filters } = useSelector((state: any) => state.PensioenbijReducer);

  const { data: events, isLoading } = usePensieonBijUnSyncEventsGetAllQuery({
    options: undefined,
    variables: { page, pageSize, status: filters.status },
  });

  const pensieonbijEvents = React.useMemo<any>(() => {
    return events?.data ?? [];
  }, [events?.data]);

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Checkbox,
        isSelectAllColumn: true,
      },
      {
        name: TableColumns.SyncStatus,
        title: <FormattedMessage id={TranslationKeys.events_content_header_state} />,
      },
      {
        name: TableColumns.Employer,
        title: <FormattedMessage id={TranslationKeys.global_employer} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.Employee,
        title: <FormattedMessage id={TranslationKeys.events_content_header_employee} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.StartDate,
        title: <FormattedMessage id={TranslationKeys.events_content_header_startDate} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.MutationType,
        title: <FormattedMessage id={TranslationKeys.events_content_header_type} />,
        textAlign: 'center',
      },
    ];
  }, []);

  const getTypeTranslation = React.useCallback((value: string | null, type: string | null) => {
    if (value) {
      switch (type) {
        case TableColumns.MutationType:
          return TranslationKeys[`events_type_${value}` as keyof typeof TranslationKeys];
        default:
          return TranslationKeys[`connection_processes_result_${value}` as keyof typeof TranslationKeys];
      }
    }

    return TranslationKeys.global_selectUnknown;
  }, []);

  const onToggleRow = React.useCallback(
    (id: string) => {
      dispatch(StoreActions.PensioenbijActions.toggleRow(id));
    },
    [dispatch],
  );

  const buildCheckboxColumn = React.useCallback(
    (event: PensioenbijMutation) => {
      return (
        <Checkbox
          onChange={() => onToggleRow(event.externalId)}
          name={'select-' + event.externalId}
          checked={!!selectedRows.find((r: string) => r === event.externalId)}
        />
      );
    },
    [onToggleRow, selectedRows],
  );

  const onSelectAllChange = React.useCallback(() => {
    const areAllRowsSelected = selectedRows.length === events?.data.length;
    if (areAllRowsSelected) {
      dispatch(StoreActions.PensioenbijActions.selectAllRows([]));
    } else {
      const ids = events?.data.map((m: PensioenbijMutation) => m.externalId) ?? [];
      dispatch(StoreActions.PensioenbijActions.selectAllRows(ids));
    }
  }, [dispatch, events, selectedRows?.length]);

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<any>>(
    data => {
      return {
        data: {
          [TableColumns.Checkbox]: buildCheckboxColumn(data),
          [TableColumns.SyncStatus]: (
            <FormattedMessage id={getTypeTranslation(data.syncStatus, TableColumns.SyncStatus)} />
          ),
          [TableColumns.Employer]: <ActionLink to={`/employers/${data.employerId}`}>{data.employerName}</ActionLink>,
          [TableColumns.Employee]: (
            <ActionLink to={`/employers/${data.employerId}/employees/${data.employeeRefID}`}>
              {data.employeeFirstName + ' ' + data.employeeLastName}
            </ActionLink>
          ),
          [TableColumns.StartDate]: formatter.date(data.createdAt),
          [TableColumns.MutationType]: (
            <FormattedMessage
              id={
                getTypeTranslation(data.mutationType, TableColumns.MutationType) ?? TranslationKeys.global_selectUnknown
              }
            />
          ),
        },
      };
    },
    [buildCheckboxColumn, getTypeTranslation],
  );

  const onResendSelectedEvents = React.useCallback(() => {
    if (selectedRows.length > 0) {
      const method = 'resendSelectedEvents';
      resendSelectedMutations(
        {
          mutationIds: selectedRows as string[],
          connectionId: '01939b86-e07d-7bb5-b409-89a3e2e1c672',
        },
        {
          onSuccess: () => {
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [resendSelectedMutations, selectedRows, showErrorSnakeBar, showSuccessSnakeBar]);

  const onResendAllEvents = React.useCallback(() => {
    dispatch(StoreActions.PensioenbijActions.selectAllRows([]));
    onResendSelectedEvents();
  }, [dispatch, onResendSelectedEvents]);

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (rowSize) {
        dispatch(StoreActions.PensioenbijActions.setPageSize(rowSize));
      }
      if (page) {
        dispatch(StoreActions.PensioenbijActions.changePage(page));
      }
    },
    [dispatch],
  );

  const applyFilter = useCallback(
    filter => {
      dispatch(StoreActions.PensioenbijActions.applyFilter(filter.name, filter.value));
    },
    [dispatch],
  );

  const pagination = React.useMemo(() => {
    return {
      page,
      pageSize,
      totalCount: events?.totalItems,
    };
  }, [page, pageSize, events?.totalItems]);

  return (
    <Box marginTop={1}>
      <EventsToolbar
        hasSelectedItems={selectedRows.length > 0}
        onChangeStatus={applyFilter}
        processEvents={onResendSelectedEvents}
        processAllEvents={onResendAllEvents}
      />
      <FilterableTable
        columns={tableHeaders}
        rows={pensieonbijEvents}
        rowMapper={tableRowMapper}
        selectedRowsIds={selectedRows}
        onSelectAllChange={onSelectAllChange}
        loading={isLoading}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
      />
    </Box>
  );
};
