import { styled } from '@mui/material/styles';
import { Button, FormControl, inputLabelClasses } from '@mui/material';

import { DatePicker, Select } from '../../../../components/FormikField';
import Dropdown from '../../../Connections/Components/EventsToolbar/Dropdown';

export const DivRowStyled = styled('div', {
  name: 'DivRowStyled',
})(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

export const DropdownStyled = styled(Dropdown, {
  name: 'DropdownStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const ResetButtonStyled = styled(Button, {
  name: 'ResetButtonStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const FormControlStyled = styled(FormControl, {
  name: 'FormControlStyled',
})(() => ({
  minWidth: '150px',
  marginRight: '5px',
  [`& .${inputLabelClasses.outlined}`]: {
    [`&:not(.${inputLabelClasses.shrink})`]: {
      transform: 'translate(14px, 5px) scale(1)',
    },
  },
}));

export const SpacerStyled = styled('span', {
  name: 'SpacerStyled',
})(() => ({
  flexGrow: 1,
}));

export const commonFilterItemStyles = {
  minWidth: '150px',
  marginRight: '5px',
};

export const SelectStyled = styled(Select, {
  name: 'SelectStyled',
})(() => commonFilterItemStyles);

export const DatePickerStyled = styled(DatePicker, {
  name: 'DatePickerStyled',
})(() => commonFilterItemStyles);
