import { useMutation } from '@tanstack/react-query';
import type { ResendPensieonBijEventsArgs } from '../../../Domain/Tools/Services';
import { toolsServices } from '../../../Domain/Tools/Services';

const mutationFn = (data: ResendPensieonBijEventsArgs) => {
  return toolsServices.resendPensieonBijEvents(data);
};

export const useResendPensioenbijMutation = () => {
  return useMutation<unknown, unknown, ResendPensieonBijEventsArgs>(mutationFn);
};
