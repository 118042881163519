import { Grid } from '@mui/material';
import type { UseQueryRefetch } from '../../../../types';
import { RegulationsPanel } from '../RegulationsPanel';
import React from 'react';
import { GROUP_KEYS, type RegulationsFormGroup, type RegulationsFromGroupKeys } from '../RegulationsPanelForm';
import type { SummaryOfRegulation } from '../../../../hooks';

const sortByDescription = (a: SummaryOfRegulation, b: SummaryOfRegulation) => {
  if (a.defaultDescription < b.defaultDescription) {
    return -1;
  }
  if (a.defaultDescription > b.defaultDescription) {
    return 1;
  }
  return 0;
};

const dataReducer = (keys: Array<RegulationsFromGroupKeys>, groups: RegulationsFormGroup) => {
  const data: RegulationsFormGroup = keys.reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {} as RegulationsFormGroup);

  for (const groupKey in groups) {
    const parentKey = keys.find(k => groupKey.startsWith(`${k}.`));

    if (parentKey) {
      if (!data[parentKey]) {
        data[parentKey] = [];
      }
      data[parentKey] = [...(data[parentKey] || []), ...groups[groupKey as RegulationsFromGroupKeys]];
    }
  }

  return keys.reduce((acc, k) => {
    const sortedRegulations = (groups[k] || []).concat(data[k] || []).sort(sortByDescription);

    if (sortedRegulations.length > 0) {
      acc[k] = sortedRegulations;
    }
    return acc;
  }, {} as RegulationsFormGroup);
};

type PolicyPackageRegulationsProps = {
  loading: boolean;
  ownerId: string;
  employerId?: string;
  product: any;
  editing: boolean;
  isEmployer: boolean;
  refetchRegulationsQuery: UseQueryRefetch;
  withRegulationsEmployerScales?: boolean;
  groups: RegulationsFormGroup;
  header: any;
};

export const PolicyPackageRegulations = ({
  loading,
  editing,
  isEmployer,
  groups,
  header,
}: PolicyPackageRegulationsProps) => {
  const AMPLIO_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.Amplio], groups);
  }, [groups]);

  const LOONSOM_VERZUIMVERZEKERING_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.LoonsomVerzuimverzekering], groups);
  }, [groups]);

  const WGA_ERD_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.WgaERD], groups);
  }, [groups]);

  const WGA_GAT_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.WgaGat], groups);
  }, [groups]);

  const WIA_VASTE_AANVULLING_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.WiaVasteAanvulling], groups);
  }, [groups]);

  const WIA_EXCEDENT_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.WiaExcedent], groups);
  }, [groups]);

  const COLLECTIVE_ONGEVALLEN_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.CollectiveOngevallen], groups);
  }, [groups]);

  const ZW_ERD_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.ZwERD], groups);
  }, [groups]);

  const INKOMENSAANVULLING_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.Inkomensaanvulling], groups);
  }, [groups]);

  const DEFAULT_BLOCK = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.Default], groups);
  }, [groups]);

  return (
    <Grid container spacing={2}>
      {hasAnyRegulations(AMPLIO_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={AMPLIO_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(LOONSOM_VERZUIMVERZEKERING_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={LOONSOM_VERZUIMVERZEKERING_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(WGA_ERD_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={WGA_ERD_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(WGA_GAT_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={WGA_GAT_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(WIA_VASTE_AANVULLING_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={WIA_VASTE_AANVULLING_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(WIA_EXCEDENT_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={WIA_EXCEDENT_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(COLLECTIVE_ONGEVALLEN_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={COLLECTIVE_ONGEVALLEN_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(ZW_ERD_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={ZW_ERD_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(INKOMENSAANVULLING_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={INKOMENSAANVULLING_BLOCK}
            isEmployer={isEmployer}
            editing={false}
          />
        </Grid>
      )}
      {hasAnyRegulations(DEFAULT_BLOCK) && (
        <Grid item xs={6}>
          <RegulationsPanel
            header={''}
            loading={loading}
            regulations={DEFAULT_BLOCK}
            isEmployer={isEmployer}
            editing={editing}
          />
        </Grid>
      )}
    </Grid>
  );
};

const hasAnyRegulations = (regulations: RegulationsFormGroup) => {
  return Object.values(regulations).some(group => group.length > 0);
};
