import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import type { SummaryOfRegulation } from '../../../../hooks/api/regulations/useRegulationsGetRegulationsQuery';

import type { EditRegulationsParams } from '../../Service/RegulationsService';

import { isSubPolicy, type RegulationsFormGroup, type RegulationsFromGroupKeys } from '../RegulationsPanelForm';

import type { Field } from '../../../../types/field.types';
import type {
  RegulationPanelPanelRow,
  RowLabel,
} from 'app/components/RegulationPanel/RegulationPanel/RegulationPanel.types';
import RegulationPanel from 'app/components/RegulationPanel/RegulationPanel';

type ExtendedOptionsInput = SummaryOfRegulation['options']['input'] & { localDate: boolean };

type ExtendedField = Field & { onReset?: () => void };

type RegulationsPanelProps = {
  header?: string | React.ReactElement;
  regulations: RegulationsFormGroup;
  loading: boolean;
  isEmployer?: boolean;
  editing: boolean;
};

export const RegulationsPanel = ({
  header,
  regulations,
  loading,
  isEmployer = false,
  editing,
}: RegulationsPanelProps) => {
  const { initialValues, values, setFieldValue, submitForm, resetForm } =
    useFormikContext<EditRegulationsParams['values']>();

  const onResetValueButtonClick = React.useCallback(
    (fieldName, defaultValue) => () => {
      if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
        resetForm();
      }
      setFieldValue(fieldName, defaultValue);
      submitForm();
    },
    [initialValues, resetForm, setFieldValue, submitForm, values],
  );

  const columns = useMemo(() => {
    return Object.keys(regulations).map(groupName => {
      const rows: Array<RegulationPanelPanelRow> = (regulations[groupName as RegulationsFromGroupKeys] ?? []).map(
        value => {
          const inputOptions = value.options?.input || {};
          const items = inputOptions?.options || [];

          const editableLabels = !isEmployer;

          const isEditableLabelAndHasBeenChanged =
            editableLabels && !value.usedInCalculations && value.description !== value.defaultDescription;

          const fieldInputName = `${value.summaryOfRegulationId}.value`;

          const field: ExtendedField = {
            type: value.fieldType ?? 'text',
            name: fieldInputName,
            variant: 'slim',
            value: value.value,
            options: inputOptions,
            items: items,
            required: value.usedInCalculations ?? false,
          };

          if (field.type === 'date') {
            field.options = {
              ...field.options,
              localDate: true,
            } as ExtendedOptionsInput;
          }

          if (value.flagged) {
            field.highlight = isEmployer;
            field.onReset = isEmployer ? onResetValueButtonClick(fieldInputName, value.defaultValue) : undefined;
          }

          let label: RowLabel | undefined = undefined;
          if (editableLabels) {
            const labelInputName = `${value.summaryOfRegulationId}.description`;

            label = {
              name: labelInputName,
              defaultValue: initialValues[value.summaryOfRegulationId]?.description,
              variant: 'slim',
              required: true,
              disabled: value.usedInCalculations,
            };

            if (isEditableLabelAndHasBeenChanged) {
              label.onReset = onResetValueButtonClick(labelInputName, value.defaultDescription);
            }
          }

          return {
            description: value.description,
            groupName: isSubPolicy(value.groupName) ? value.groupName : '',
            value: {
              name: field.name,
              editable: editing,
              field,
              label,
            },
          };
        },
      );

      return {
        title: groupName,
        rows: rows,
      };
    });
  }, [isEmployer, editing, initialValues, onResetValueButtonClick, regulations]);

  const rowKeys = columns.map(c => Object.keys(c.rows)).flat();
  if (!rowKeys.length) {
    return null;
  }

  return <RegulationPanel header={header} columns={columns} loading={loading} />;
};
