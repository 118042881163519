import React from 'react';
import { injectIntl } from 'react-intl';
import { HasAccessTo } from 'app/components/Authorization';
import { EVENT } from 'app/common/Authorization/entities';
import { EDIT } from 'app/common/Authorization/permissions';

import { DivRowStyled, DropdownStyled, SpacerStyled } from './EventsToolbar.styles';

const EventsToolbar = (props: any) => {
  const { processEvents, processAllEvents, hasSelectedItems, intl } = props;

  const disabledIfNoAccessToEventEdit = !HasAccessTo(EVENT, EDIT);

  const dropdownOptions = {
    process: [
      {
        title: intl.formatMessage({ id: 'events.processAction' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.processConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.processConfirmMessage' }),
        },
        action: processEvents,
        disabled: disabledIfNoAccessToEventEdit || !hasSelectedItems,
      },
      {
        title: intl.formatMessage({ id: 'events.processAllAction' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.processConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.processAllConfirmMessage' }),
        },
        action: processAllEvents,
        disabled: disabledIfNoAccessToEventEdit,
      },
    ],
  };

  return (
    <>
      <DivRowStyled>
        <SpacerStyled />
        <DropdownStyled options={dropdownOptions.process} intl />
      </DivRowStyled>
    </>
  );
};

export default injectIntl(EventsToolbar);
