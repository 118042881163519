import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, Collapse, Grid, IconButton } from '@mui/material';
import { faChevronDown, faChevronUp, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';

import { isNil, isObject } from 'app/utils';

import {
  FontAwesomeIconStyled,
  ListItemStyled,
  ListStyled,
  ListSubheaderStyled,
  SpanFieldLabelStyled,
  SpanFiledValueStyled,
} from './RegulationPanelRow.styles';
import { InputFieldFactory, RenderFieldFactory } from 'app/components/FormikField';
import { IntlMessage } from 'app/components/Intl';
import { RegulationPanelPanelRowValueWithReset } from './RegulationPanelRowValueWithReset';
import type { RegulationPanelPanelRow as RegulationPanelPanelRowType } from '../RegulationPanel/RegulationPanel.types';
import type { Field } from 'app/types';
import { TypographyColumnTitleStyled } from '../RegulationPanel/RegulationPanel.styles';

export const DETAILS_PANEL_ROW_DATA_TEST_ID = 'details-panel-row';
export const DETAILS_PANEL_ROW_DELETE_BUTTON_DATA_TEST_ID = 'details-panel-row-delete-button';
export const DETAILS_PANEL_ROW_EDIT_BUTTON_DATA_TEST_ID = 'details-panel-row-edit-button';
export const DETAILS_PANEL_ROW_EXPAND_BUTTON_DATA_TEST_ID = 'details-panel-row-expand-button';

type OnExpandedFunc = ({ id, expanded }: { id: number; expanded: boolean }) => void;

type RegulationPanelPanelRowProps = {
  rows: RegulationPanelPanelRowType[];
  expanded?: boolean;
  id?: number;
  title?: React.ReactElement | string;
  config?: any;
  loading?: boolean;
  disabled?: boolean;
  onExpand?: OnExpandedFunc;
  onEditButtonClick?: () => void;
  onDeleteButtonClick?: () => void;
  disabledDeleteButton?: boolean;
  disabledEditButton?: boolean;
};

const RegulationPanelPanelRow = ({
  rows,
  id,
  title,
  config,
  onExpand,
  expanded,
  onEditButtonClick,
  onDeleteButtonClick,
  loading = false,
  disabled = false,
  disabledDeleteButton = false,
  disabledEditButton = false,
}: RegulationPanelPanelRowProps) => {
  const isExpandable = typeof expanded !== 'undefined' && expanded !== null;
  const hasHeader = !!title || isExpandable;
  const initialState = isExpandable ? expanded : true;
  const skeletonRows = config?.skeletonRows || 5;

  const [visible, setVisible] = React.useState(initialState);

  React.useEffect(() => {
    setVisible(initialState);
  }, [initialState]);

  const onClick = React.useCallback(
    event => {
      event.stopPropagation();
      const newState = !visible;
      setVisible(newState);
      if (onExpand && typeof id === 'number') {
        onExpand({ id, expanded: visible });
      }
    },
    [id, onExpand, visible],
  );

  const skeletonRowElements = React.useMemo(() => {
    return new Array(skeletonRows).fill(null);
  }, [skeletonRows]);

  const renderFieldLabel = React.useCallback(
    (row: RegulationPanelPanelRowType, key) => {
      const rowValue = row.value;
      if (loading) {
        return <Skeleton width={'100%'} height={'100%'} />;
      }

      const shouldRenderInputLabel = rowValue?.editable && !!rowValue?.label;
      const isLabelHighlighted = rowValue?.label?.highlight || false;

      if (shouldRenderInputLabel) {
        const labelFieldObject: any = {
          type: 'text',
          ...rowValue.label,
          disabled: rowValue.label?.disabled || disabled,
        };

        return <InputFieldFactory field={labelFieldObject} />;
      }

      const label = (
        <SpanFieldLabelStyled disabled={disabled} highlighted={isLabelHighlighted}>
          <IntlMessage value={key} />
        </SpanFieldLabelStyled>
      );

      if (rowValue.label && rowValue.label.onReset) {
        return <RegulationPanelPanelRowValueWithReset value={label} onReset={rowValue.label.onReset} />;
      }

      return label;
    },
    [disabled, loading],
  );

  const renderValue = React.useCallback(
    (row: RegulationPanelPanelRowType) => {
      if (loading) {
        return <Skeleton width={'100%'} height={'100%'} />;
      }

      const rowValue = row.value;
      const isEditable = rowValue?.editable === true;
      const field = isObject(rowValue) && !isNil(rowValue?.field) ? rowValue.field : defaultField();
      const isValueResettable = !!field?.onReset;
      const isValueHighlighted = field?.highlight || false;
      const isDisabled = field?.disabled || disabled;

      const renderItem = (item: any, idx: number) => (
        <ListItemStyled key={`${item.key}-${idx}`}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5}>
              <SpanFieldLabelStyled disabled={isDisabled} highlighted={isValueHighlighted}>
                {item.key}
              </SpanFieldLabelStyled>
            </Grid>
            <Grid item xs={12} sm={7}>
              <SpanFiledValueStyled disabled={isDisabled} highlighted={isValueHighlighted}>
                {item.value}
              </SpanFiledValueStyled>
            </Grid>
          </Grid>
        </ListItemStyled>
      );

      const renderValueContent = () => {
        if (isEditable) {
          return <InputFieldFactory field={field} />;
        }

        const valueComponent = (
          <SpanFiledValueStyled disabled={isDisabled} highlighted={isValueHighlighted}>
            <RenderFieldFactory field={field} />
          </SpanFiledValueStyled>
        );

        return valueComponent;
      };

      const valueContent = renderValueContent();

      if (isValueResettable) {
        return <RegulationPanelPanelRowValueWithReset value={valueContent} onReset={field.onReset} />;
      }

      if (Array.isArray(field.value)) {
        return field.value.map(renderItem);
      }

      return valueContent;
    },
    [disabled, loading],
  );

  const renderedRows = React.useMemo(() => {
    let rowsToRender = rows;
    if (rowsToRender.length === 0 && loading) {
      rowsToRender = skeletonRowElements;
    }

    const groupedRows: { [key: string]: RegulationPanelPanelRowType[] } = rowsToRender.reduce(
      (acc: { [key: string]: RegulationPanelPanelRowType[] }, row: RegulationPanelPanelRowType) => {
        const groupName = row.groupName || '';
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(row);
        return acc;
      },
      {},
    );

    const renderRows = (rows: RegulationPanelPanelRowType[]) =>
      rows.map((row: RegulationPanelPanelRowType, index) => {
        const label = renderFieldLabel(row, row.description);
        const value = renderValue(row);
        const hasKey = Array.isArray(rowsToRender) && !!row.description;

        return (
          <ListItemStyled key={`${index}`}>
            <Grid container spacing={1}>
              {hasKey && (
                <Grid item xs={12} sm={5}>
                  {label}
                </Grid>
              )}
              <Grid item xs={12} sm={hasKey ? 7 : 12}>
                {value}
              </Grid>
            </Grid>
          </ListItemStyled>
        );
      });

    const renderedGroups: React.ReactNode[] = [];

    // Render rows without a groupName first
    if (groupedRows['']) {
      renderedGroups.push(...renderRows(groupedRows['']));
      delete groupedRows['']; // Remove the empty group after rendering
    }

    // Render the remaining grouped rows
    Object.entries(groupedRows).forEach(([groupName, rowsInGroup]) => {
      renderedGroups.push(
        <React.Fragment key={groupName}>
          <TypographyColumnTitleStyled variant={'subtitle2'} withMarginTop={!!groupName} withMarginBottom={false}>
            <IntlMessage value={groupName} />
          </TypographyColumnTitleStyled>
          {renderRows(rowsInGroup)}
        </React.Fragment>,
      );
    });

    return renderedGroups;
  }, [rows, loading, skeletonRowElements, renderFieldLabel, renderValue]);

  const subHeader = React.useMemo(() => {
    if (!hasHeader) {
      return undefined;
    }

    return (
      <ListSubheaderStyled>
        <Box>{title}</Box>
        {(isExpandable || !!onEditButtonClick || !!onDeleteButtonClick) && (
          <Box>
            {!!onDeleteButtonClick && (
              <IconButton
                data-testid={DETAILS_PANEL_ROW_DELETE_BUTTON_DATA_TEST_ID}
                color="primary"
                onClick={onDeleteButtonClick}
                disabled={disabledDeleteButton}
                size="small"
              >
                <FontAwesomeIconStyled icon={faTrash} />
              </IconButton>
            )}
            {!!onEditButtonClick && (
              <IconButton
                data-testid={DETAILS_PANEL_ROW_EDIT_BUTTON_DATA_TEST_ID}
                color="primary"
                onClick={onEditButtonClick}
                disabled={disabledEditButton}
                size="small"
              >
                <FontAwesomeIconStyled icon={faPenToSquare} />
              </IconButton>
            )}
            {isExpandable && (
              <IconButton
                data-testid={DETAILS_PANEL_ROW_EXPAND_BUTTON_DATA_TEST_ID}
                color="primary"
                onClick={onClick}
                size="small"
              >
                <FontAwesomeIconStyled icon={visible ? faChevronUp : faChevronDown} />
              </IconButton>
            )}
          </Box>
        )}
      </ListSubheaderStyled>
    );
  }, [
    disabledDeleteButton,
    disabledEditButton,
    hasHeader,
    isExpandable,
    onClick,
    onDeleteButtonClick,
    onEditButtonClick,
    title,
    visible,
  ]);

  return (
    <>
      <ListStyled data-testid={DETAILS_PANEL_ROW_DATA_TEST_ID} subheader={subHeader}>
        <Collapse in={visible}>{renderedRows}</Collapse>
      </ListStyled>
    </>
  );
};

export default React.memo(RegulationPanelPanelRow);

const defaultField = (): Field => {
  return {
    name: '',
    type: 'text',
    variant: 'outlined',
    label: '',
    value: '',
    optionsEntity: '',
    disabled: false,
    required: false,
  };
};
