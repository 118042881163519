import type { SummaryOfRegulation } from '../../../../hooks';

export const GROUP_KEYS = {
  OverviewRegulations: 'summaryOfRegulations.group.overviewRegulations',
  Employees: 'summaryOfRegulations.group.employees',
  Scales: 'summaryOfRegulations.group.scales',
  PartnerPension: 'summaryOfRegulations.group.partnerPension',
  OrphanPension: 'summaryOfRegulations.group.orphanPension',
  DisabilityPension: 'summaryOfRegulations.group.disabilityPension',
  SurvivorsBridgingPension: 'summaryOfRegulations.group.survivorsBridgingPension',

  LoonsomVerzuimverzekering: 'summaryOfRegulations.group.LOONSOM_VERZUIMVERZEKERING',
  WgaERD: 'summaryOfRegulations.group.WGA_ERD',
  WgaGat: 'summaryOfRegulations.group.WGA_GAT',
  WiaVasteAanvulling: 'summaryOfRegulations.group.WIA_VASTE_AANVULLING',
  WiaExcedent: 'summaryOfRegulations.group.WIA_EXCEDENT',
  CollectiveOngevallen: 'summaryOfRegulations.group.COLLECTIVE_ONGEVALLEN',
  ZwERD: 'summaryOfRegulations.group.ZW_ERD',
  Amplio: 'summaryOfRegulations.group.AMPLIO',
  Inkomensaanvulling: 'summaryOfRegulations.group.INKOMENSAAVULL',

  Default: 'summaryOfRegulations.group.policyPackage',
} as const;

const GROUP_VALUES = Object.values(GROUP_KEYS);

export type RegulationsFromGroupKeys = (typeof GROUP_VALUES)[number];

export type RegulationsFormGroup = Record<RegulationsFromGroupKeys, Array<SummaryOfRegulation>>;

export const isSubPolicy = (key: string) => {
  return key.split('.').length > 3;
};
