import axios from 'axios';

export type GetPensieonBijEventsArgs = {
  page?: number;
  pageSize?: number;
  status?: string[];
};

export type ResendPensieonBijEventsArgs = {
  connectionId: string | null;
  mutationIds: string[];
};

export const toolsServices = {
  async getPensieonBijEvents({ page, pageSize, status }: GetPensieonBijEventsArgs) {
    return axios.get(`connections/mutations`, { params: { page, pageSize, status } });
  },

  async resendPensieonBijEvents({ connectionId, mutationIds }: ResendPensieonBijEventsArgs) {
    return axios.post(`connections/mutations`, { connectionId, mutationIds });
  },
};
