import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import InputFieldFactory from '../../../../../components/FormikField/Factory/InputFieldFactory';
import { TranslationKeys } from '../../../../../translations';
import { useIntl } from 'react-intl';
import { IntlMessage } from '../../../../../components/Intl';
import { type SingleSelectedState, StoreSelectors, useStoreSelector } from '../../../../../store';
import type { Employer } from '../../../../../types';
import { employerService } from '../../../Services/EmployerService';
import { useSnakeBar } from '../../../../../hooks';

export const EmployerPensieonbijTab = () => {
  const { showErrorSnakeBar, showSuccessSnakeBar } = useSnakeBar();
  const { item: employer } = useStoreSelector<SingleSelectedState<Employer>>(state =>
    StoreSelectors.EmployerSelector.selectEmployer(state.EmployerReducer),
  );

  const [numberOfTmoments, setNumberOfTmoments] = useState<number>(employer?.tMoments?.length ?? 0);

  const [tMoments, setTMoments] = useState<number[]>(employer?.tMoments ?? []);

  const intl = useIntl();

  useEffect(() => {
    setNumberOfTmoments(employer?.tMoments?.length ?? 0);
    setTMoments(employer?.tMoments ?? []);
  }, [employer]);

  const onChangeTmomentsInput = useCallback((input: { value: string }) => {
    const newNumberOfTmoments = Number(input.value) || 0;
    setNumberOfTmoments(newNumberOfTmoments);

    setTMoments(prevTMoments => {
      const updatedTMoments = [...prevTMoments];
      if (newNumberOfTmoments > prevTMoments.length) {
        return [...updatedTMoments, ...new Array(newNumberOfTmoments - prevTMoments.length).fill(0)];
      } else {
        return updatedTMoments.slice(0, newNumberOfTmoments);
      }
    });
  }, []);

  const onChangeTMoment = useCallback((index: number, value: string) => {
    setTMoments(prevTMoments => {
      const updatedTMoments = [...prevTMoments];
      updatedTMoments[index] = Number(value) || 0;
      return updatedTMoments;
    });
  }, []);

  const onSave = React.useCallback(async () => {
    const method = 'updateEmployerTmoments';
    try {
      await employerService
        .updateEmployerTmoments({
          ownerId: employer?.ownerId,
          employerId: employer?.employerId,
          tmoments: tMoments,
        })
        .then(() => {
          showSuccessSnakeBar({ method });
        });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      showErrorSnakeBar({ method, message: error });
    }
  }, [employer?.employerId, employer?.ownerId, showErrorSnakeBar, showSuccessSnakeBar, tMoments]);

  return (
    <Box>
      <Grid item xs={4} md marginBottom="10px">
        <InputFieldFactory
          key={`ib-report-employment-end-date-filter`}
          onChange={onChangeTmomentsInput}
          field={{
            type: 'number',
            name: TranslationKeys.global_dateTo,
            label: intl.formatMessage({ id: TranslationKeys.global_number_t_moments }),
            fullWidth: false,
            value: numberOfTmoments,
            required: false,
            validate: false,
          }}
        />
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>T Moments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: numberOfTmoments }).map((_, index) => (
            <TableRow key={index}>
              <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <InputFieldFactory
                  field={{
                    name: `tMoment-${index}`,
                    type: 'number',
                    fullWidth: false,
                    value: tMoments[index] || '',
                  }}
                  onChange={(input: any) => onChangeTMoment(index, input.value)}
                />
                Jaar
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Grid item xs={4} md marginTop="10px" textAlign="right">
        <Button variant="contained" onClick={onSave}>
          <IntlMessage value={TranslationKeys.global_saveChanges} />
        </Button>
      </Grid>
    </Box>
  );
};
